import styled from 'styled-components';
import * as React from 'react';

const Heading = styled.div`
  margin-bottom: 3.5rem;
`;

const Title = styled.h1`
  margin-bottom: .75rem;
`;

const Subtitle = styled.p`
  font-style: italic;
`;

export default ({ title, subtitle = '', children, ...rest }) => (
  <Heading {...rest}>
    <Title>{title || children}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Heading>
);
