import * as React from 'react';
import styled from 'styled-components';
import { Normalize } from 'styled-normalize';
import { Helmet } from 'react-helmet';
import 'typeface-et-book';
import etBook from 'typeface-et-book/et-book/et-book/et-book-roman-line-figures/et-book-roman-line-figures.woff';
import etBookItalic
  from 'typeface-et-book/et-book/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.woff';
import etBookBold from 'typeface-et-book/et-book/et-book/et-book-bold-line-figures/et-book-bold-line-figures.woff';

import '../style/global.scss';
import Breadcrumb from './breadcrumb';
import Nav from './nav';

const Container = styled.div`
  position: relative;
  max-width: 680px;
  margin: auto;
  padding: 1rem;
  min-height: calc(100vh - 2rem);
  
  @media screen and (min-width: 481px) {
    //padding: .5rem;
  }

  @media screen and (min-width: 769px) {
    padding: 1.25rem 96px;
    min-height: calc(100vh - 2.5rem);
  }
`;

const Content = styled.main`
  position: relative;
  padding: 114px 0;
`;

const Header = styled.header`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  width: 100%;
`;

const Footer = styled.footer`
  border-top: 2px solid;
  padding: 1.25rem 0 0;
`;

const FooterNav = styled(Nav)`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    
    @media screen and (min-width: 481px) {
      display: flex;
      justify-content: space-between;
    }
   
  }
  
  li {
    display: block;
    margin-bottom: .75rem;
  }
  
  a {
    text-decoration: none;
    font-size: 1.125rem;
    //text-transform: lowercase;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Layout = ({ children, breadcrumbTrail, pageTitle, pageDescription, withFooter = true }) => {
  const description = pageDescription || 'Poet and visual artist Linda Black, author of poetry collections including Slant and The Son of a Shoemaker and editor of Long Poem Magazine';
  const title = pageTitle ? `${pageTitle} | Linda Black` : 'Linda Black';
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{title}</title>
        <meta name="description" content={description}/>
        {[
          etBook,
          etBookItalic,
          etBookBold,
        ].map(font => (
          <link key={font}
                rel="preload"
                as="font"
                href={font}
                type="font/woff"
                crossOrigin="anonymous"/>
        ))}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        {/* todo */}
        {/*<meta property="og:image" content=""/>*/}
        {/*<meta property="og:url" content=""/>*/}
      </Helmet>
      <Normalize/>
      <Container>
        <Content>
          <Header>
            {breadcrumbTrail && <Breadcrumb trail={breadcrumbTrail}/>}
          </Header>
          {children}
        </Content>
        {withFooter ? (
          <Footer>
            <FooterNav withHome={true}/>
          </Footer>
        ) : ''}
      </Container>
    </>
  );
};

export default Layout;
