const PAGES = {
  HOME: {
    path: '/',
    label: 'Home',
  },
  POEMS: {
    path: '/poems/',
    label: 'Poems',
  },
  COLLECTIONS: {
    path: '/collections/',
    label: 'Collections',
  },
  ARTWORK: {
    path: '/artwork/',
    label: 'Artwork',
  },
  ABOUT: {
    path: '/about/',
    label: 'About',
  },
  NOT_FOUND: {
    path: '/404/',
    label: '404',
  },
};

module.exports = { PAGES };
