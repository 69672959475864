import * as React from 'react';
import { Link } from 'gatsby';

import { PAGES } from '../config/constants';

const NavItem = ({ path, label }) => <li><Link to={path}>{label}</Link></li>;

const Nav = ({ withHome, ...other }) => (
  <nav {...other}>
    <ul>
      {withHome ? <NavItem {...PAGES.HOME}/> : ''}
      <NavItem {...PAGES.POEMS}/>
      <NavItem {...PAGES.COLLECTIONS}/>
      <NavItem {...PAGES.ARTWORK}/>
      <NavItem {...PAGES.ABOUT}/>
    </ul>
  </nav>
);

export default Nav;
