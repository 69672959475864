import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { PAGES } from '../config/constants';

const CustomLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-size: 0.9375rem;
  text-transform: lowercase;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Breadcrumb = ({ trail }) => (
  <nav>
    <CustomLink to={PAGES.HOME.path}>{PAGES.HOME.label}</CustomLink>
    {trail.map(({ path, label }) => (
      <span key={path}>
        {' / '}
        <CustomLink to={path}>{label}</CustomLink>
      </span>
    ))}
  </nav>
);

export default Breadcrumb;
